import React from "react";
import "./Blog.css";

const BLOG_POST = {
  title: "Start Here",
  slug: "1-white-paper",
  feature_image: require("../components/assets/blog-feature-image.png"),
  published_at: "2023-09-14",
  primary_author: {
    name: "Aris Vinsant",
    profile_image: require("../components/assets/blog-headshot.jpg")
  }
};

function Blog() {
  return (
    <div className="Blog">
      <h1 className="h-1">Blog</h1>
      <div className="post-container">
        <a href={`/post/${BLOG_POST.slug}`} className="post-card">
          <img
            src={BLOG_POST.feature_image}
            alt={BLOG_POST.title}
            className="post-image"
          />
          <div className="post-details">
            <span className="post-date">
              {new Date(BLOG_POST.published_at).toLocaleDateString("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric",
              })}
            </span>
            <h2 className="post-title">{BLOG_POST.title}</h2>
            <div className="post-author">
              <img
                src={BLOG_POST.primary_author.profile_image}
                alt={BLOG_POST.primary_author.name}
                className="author-image"
              />
              <span className="author-name">
                {BLOG_POST.primary_author.name}
              </span>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Blog;

import React from 'react';
import './BlogPost.css'

const BLOG_POST = {
  title: "Start Here",
  feature_image: require("../components/assets/blog-feature-image-2.png"),
  published_at: "2023-09-14",
  html: `
<p>When buying insurance, people usually end up with a negative financial outcome. Most people will never receive insurance settlements to outweigh the policy premiums paid over time. Otherwise, insurance companies wouldn't make money. Just look at the world's largest insurer, UnitedHealth, which will generate $30B in earnings this year. Insurance companies are not in the business of operating at a loss. </p>
<p>But people still buy insurance. They prefer to incur small losses over an extended period rather than face the uncertainty of a sudden and substantial loss.</p>
<h2 id="introduction">Introduction</h2>
<p>Financial derivative contracts and insurance contracts are very similar. You can either buy risk, or you can sell risk.</p>
<p>When you buy stock options, you are buying risk. You believe there is an unknown variable not currently reflected in the price of a financial asset. You also believe that variable will materialize into a price adjustment on or before a specific date, delivering magnificent gains.</p>
<p>But who is willing to take the counter-party risk? Someone that can give up an infrequent large loss in exchange for many small gains accumulating to a net positive over time. Just like an insurance company.</p>
<p>Historically, being on the side of the insurance company is more profitable. The same goes for selling options.</p>
<h2 id="volatility">Volatility</h2>
<p>Volatility is a critical element in determining the value of options. Implied volatility (IV) is the market's expectation of future price fluctuations.</p>
<p>Options associated with higher implied volatility are worth more. A stable consumer-goods company has lower implied volatility compared to a dynamic technology stock because the stock price does not move around as much. With the tech stock, the probability of a massive price swing is higher, and option-buyers must pay up to play that game.</p>
<p>The Volatility Index (VIX) is a standardized measure of implied volatility. Traders use the VIX to assess expected future movements in the S&amp;P 500 index over the next 30 days. Typically, the VIX hovers around 20, meaning the market expects the S&amp;P 500 to fluctuate at an annualized rate of 20% over the next 30 days.</p>
<figure class="kg-card kg-image-card kg-card-hascaption">
   <img src="https://lh3.googleusercontent.com/xL5qqOt9VgSSQggAZzDV23Z55TgdQLbXBx7H--zMgFeNumfBozaQsYBcsKRQrVCRjx9crHcmJGhf2qJUjkPnA3fQ2wbKr9yW7dR7GeOLPs40te4wT342Zb12IS3J0LSLcPeLajIx-rlcn-RrRpglPIs" class="kg-image" alt="" loading="lazy" width="220" height="651">
   <figcaption><span style="white-space: pre-wrap;">Source: </span><a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3393940&ref=income-with-options.ghost.io#:~:text=From%201990%20to%202018%2C%20the,implying%20the%20difference%20of%204.2%25."><span style="white-space: pre-wrap;">Oleg Bondarenko</span></a></figcaption>
</figure>
<p>Over the past three decades, the markets have consistently overestimated future volatility. Between 1990 and 2018, the average implied volatility stood at 19.3%, whereas realized volatility—the actual volatility that occurred—averaged only 15.1%. The stock market, in reality, spends more time moving sideways than many individuals account for.</p>
<p>Since option pricing relies on implied volatility rather than realized volatility, selling options has been more successful than buying options. In fact, between 1990 and 2018, realized volatility exceeded implied volatility in just one year: 2008, during the Great Financial Crisis and the subprime mortgage meltdown.</p>
<figure class="kg-card kg-image-card"><img src="https://lh4.googleusercontent.com/1eTRat3tLIlPcBf_e79lwqaLCI3rnRxtmTNt1XO_9unM7yb8WHYjYo53aNZt26Z9qiivXoTua7UrMAHpvERMM4Ru4kzs0ac3gunxU9XtpV-rmBFya2eRHlRz9xfaLw91RPauwJERAULGR0gd63KIDRI" class="kg-image" alt="" loading="lazy" width="819" height="561"></figure>
<h2 id="risk-vs-return">Risk vs. Return</h2>
<p>Everyone knows diversification is a good thing when investing. It allows the investor to shed unsystematic risk- we'll talk more about this in a future post.</p>
<p>Diversifying across asset classes, geographical regions, currencies, and securities can lead to improved risk-adjusted returns, especially when one is careful about security selection and the use of hedging instruments.</p>
<p>For the average investor, few strategies offer better risk-adjusted returns than a buy-and-hold approach consisting of low-cost index funds of US stocks, bonds, REITs, and international stocks. </p>
<p>Option-selling strategies present an appealing opportunity to achieve comparable return metrics to the buy-and-hold strategy, but with significantly lower risk. Let's consider a put-selling strategy ("PUT" in the chart below) as an example. From 1986 to 2018, the PUT strategy delivered an average annual return of 9.54%, which is similar to the S&amp;P 500's 9.80%. However, the volatility experienced by the PUT strategy was considerably lower than that of the index. PUT exhibited an average annual volatility of 9.95% compared to the significantly higher 14.93% average volatility of the S&amp;P 500. Option-selling strategies have demonstrated performance similar to stock funds while enjoying volatility levels closer to those of bond funds.</p>
<figure class="kg-card kg-image-card"><img src="https://lh5.googleusercontent.com/6uQDRlCCtTOQ__oiQEdBV1NLjowRnqKli_pqWYUFm5623VL7Httvast6sLoHkJuh1MuXPLrfvmiuTMFi1NVZJ68UXQi8-JmNv-Xl4VZxUz-WGYj2ojR-1K0DIl6-q-7IW-a9ujZMvGbreDY8KUMbpoY" class="kg-image" alt="" loading="lazy" width="826" height="624"></figure>
<figure class="kg-card kg-image-card"><img src="https://lh3.googleusercontent.com/9PQr6sWrSvZtp9aCyj1BV9lpyWroEmG3Yr-s2AMINHa3vyFr2WPNWo5TJOca2Au6sLp5FO2MjsvqZo1uhd6IugT3LrHoX8Y1qHrrZwOuio05aOUkg5MHfDk0jLkTNPiKpt9oXl0UnEP-Y7tmWuCXhHo" class="kg-image" alt="" loading="lazy" width="823" height="225"></figure>
<h2 id="individual-security-options-vs-index-options">Individual Security Options vs. Index Options</h2>
<p>This is the cornerstone of the case for incomewithoptions.com</p>
<p>The S&amp;P 500 is the leading performance benchmark for fund managers and individual investors. It consists of ~500 of the largest and most profitable companies in the United States, weighted based on market capitalization. The largest companies, such as Apple and Microsoft, carry a significant weighting in the index, while smaller companies have a minimal impact.</p>
<p>Many option-selling strategies generate income for their funds by selling options on the index, such as the S&amp;P 500 or Nasdaq 100. This approach simplifies the fund manager's task compared to selling options on individual companies within the index.</p>
<p>Let's consider a $100 million portfolio. If the goal is to simply buy and hold an index fund, the SPY ETF, which tracks the S&amp;P 500, is a popular choice. Achieving similar results with more effort would involve purchasing the individual stocks that make up the index. For instance, buying all the underlying stocks in the index would require purchasing around 40,400 shares of Apple (AAPL) and as little as around 700 shares of Evergy (EVRG), a Midwest utility company.</p>
<p>Whether one chooses to invest in the index itself or the individual stocks comprising the index, the outcome is equivalent. Performance and volatility should be  identical. The risk is the same.</p>
<p>However, there exists a significant disparity between two seemingly identical strategies: selling options on the index versus selling options on the individual positions within the index. To illustrate, let's revisit the hypothetical $100 million portfolio at the time of writing this post. Buying 228,000 shares of SPY and selling corresponding calls with a delta of 0.384 one month out could generate a total option premium of $1,128,600. On the other hand, purchasing the individual positions, such as Apple, Microsoft, United Health, and others, and selling similar-delta calls would yield a total premium of $1,748,685. Once again, the index represents a combination of the underlying positions, and investors should be indifferent to owning shares at the index or individual-security level.</p>
<p>Incomewithoptions.com exposes this disparity in a way never seen before by the public. It is easy to see how much more income you can earn owning the individual stocks.</p>
<h2 id="conclusion">Conclusion</h2>
<p>Concept IV Capital (The investment firm run by our founder Aris Vinsant) implements its investment strategy with the following key principles:</p>
<p><strong>1. Building portfolios with high quality companies.</strong> We only buy shares in bluechip stocks from the S&amp;P 500, Dow 30, and Nasdaq 100.</p>
<p><strong>2. Selling options based on implied volatility.</strong> We collect the difference between implied and realized volatility.</p>
<p><strong>3. Emphasizing risk-adjusted returns</strong>. We construct our portfolios so they should never experience higher volatility than one expects by investing in US equity low-cost index funds.</p>
<p><strong>4. Capitalizing on the spread between index-level and individual-security options.</strong> By evaluating and leveraging this spread, Concept IV Capital aims to generate favorable investment returns.</p>
<p>Through these core points, Concept IV Capital strives to implement a strategy that capitalizes on the nuances of volatility, risk management, and market pricing, and aims to deliver strong risk-adjusted returns to its investors.</p>
  `
};

function BlogPost() {

  return (
    <div className="blog-post">
      {BLOG_POST.feature_image && 
        <img 
          src={BLOG_POST.feature_image} 
          alt={BLOG_POST.title} 
          className="feature-image" 
        />
      }
      <div className="blog-body">
        <h1>{BLOG_POST.title}</h1>
        <span className="post-date">
          {new Date(BLOG_POST.published_at).toLocaleDateString('en-US', { 
            month: 'short', 
            day: '2-digit', 
            year: 'numeric' 
          })}
        </span>
        <div dangerouslySetInnerHTML={{ __html: BLOG_POST.html }} />
      </div>
    </div>
  );
}

export default BlogPost;
